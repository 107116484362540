
import { defineAsyncComponent, defineComponent, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

/** Utils */
import {
  closeSwal,
  showSwalError,
  showSwalLoading,
} from '@/utils/sweetalert2-utils';
import { createToaster } from '@meforma/vue-toaster';

import {
  addMinutesToDate,
  handleCatchErrorCustom,
} from '@/utils/general-utils';
import { TransactionStatusPlaceToPayI } from '@/modules/payments/interfaces/payments.interfaces';
import { AxiosError } from 'axios';

export default defineComponent({
  name: 'PaymentStatus',
  components: {
    Checked: defineAsyncComponent(
      () => import('@/components/shared/icons/Checked.vue')
    ),
    Error: defineAsyncComponent(
      () => import('@/components/shared/icons/Error.vue')
    ),
    Pending: defineAsyncComponent(
      () => import('@/components/shared/icons/Pending.vue')
    ),
  },
  setup() {
    const token = ref<string>('');
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const transactionStatus = ref<TransactionStatusPlaceToPayI>(
      Object.create({})
    );
    const loading = ref<boolean>(false);
    const errorOccurred = ref<boolean>(false);

    const toaster = createToaster({
      position: 'bottom',
      duration: 2000,
    });

    const getResumePay = async () => {
      try {
        loading.value = true;
        showSwalLoading();

        const payload = {
          date: addMinutesToDate(5),
          duration: 3,
        };

        const response = await store.dispatch('getPublicToken', payload);
        const { access_token } = response.data;

        if (access_token) {
          token.value = access_token;
          transactionStatus.value = await store.dispatch(
            'payment/getTransactionStatus',
            {
              transactionId: String(route.params.token),
              token: token.value,
            }
          );
          closeSwal();
        } else {
          errorOccurred.value = true;
          showSwalError('Se produjo un error al consultar la transacción.');
        }
      } catch (error) {
        errorOccurred.value = true;
        handleCatchErrorCustom(
          error as AxiosError,
          'Se produjo un error al consultar el estado de la transacción.'
        );
      } finally {
        loading.value = false;
      }
    };

    type StatusType = {
      [key: string]: string;
    };

    const setClassStatus = (status: string) => {
      const values: StatusType = {
        APPROVED: 'text-success',
        REJECTED: 'text-primary',
        PENDING: 'text-secondary',
      };

      return values[status];
    };

    const getCurrentURL = () => {
      return window.location.href;
    };

    const redirectHome = () => {
      router.push({
        name: 'Home',
      });
    };

    const urlPaymentValidator = ref(getCurrentURL());

    const onSuccess = () => {
      toaster.show('URL Copiada');
    };

    const onError = () => {
      toaster.error('Error al copiar la URL');
    };

    onMounted(() => {
      getResumePay();
    });

    return {
      transactionStatus,
      loading,
      errorOccurred,
      setClassStatus,
      getCurrentURL,
      redirectHome,
      urlPaymentValidator,
      onSuccess,
      onError,
    };
  },
});
